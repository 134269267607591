<style scoped>
.vue-box {
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;

}
.content-box {
  margin: 1em;
  padding: 1em;
  background-color: #fff;
  color: #666;
}
.el-tag {
  border-radius: 1px;
}
/*800之下*/
@media (max-width: 800px) {
  .kapian {
    width: 100% !important;
    margin-right: 0px !important;
  }
  .saoma {
    display: none;
  }
}

/deep/.el-tabs--border-card > .el-tabs__header {
  background-color: #fff;
}
/* markdown格式 */
.md-contetn {
  padding-left: 1.5em;
  line-height: 26px;
}
</style>
<style>
/* 多个ul时，切换那个啥 */
.md-contetn h4 {
  margin-left: -0.5em;
}
.md-contetn ul,
.md-contetn ol {
  padding-left: 1em;
}
.md-contetn pre {
  padding: 5px;
  background-color: #eee;
  font-family: "times new roman";
}
.sa-wnk {
  background-color: #fff;
  border: 1px #ddd solid;
  margin-bottom: 14px;
  min-height: 100px;
  cursor: pointer;
  transition: all 0.3s;
  overflow: hidden;
}
.sa-wnk:hover {
  box-shadow: 0 0 20px #999;
}
.sa-wnk img {
  float: left;
  line-height: 100px;
  margin: 25px 0px 0 20px;
  width: 50px;
  height: 50px;
  vertical-align: middle;
}
.sa-wnk .sa-wnk-tv {
  float: left;
  margin-left: 20px;
  max-width: calc(100% - 100px);
}
.sa-wnk-title {
  margin-top: 25px;
  font-size: 13px;
}
.sa-wnk-value {
  margin-top: 4px;
  font-size: 24px;
  padding-bottom: 20px;
}

.el-tabs--border-card{
  background: transparent;
  background-color: #f8fafc;
  border: none;
  border-radius: 20px;
}

.el-tabs__header{
  background-color: transparent;
  border: none;
}
[data-v-ec9d9f40] .el-tabs--border-card > .el-tabs__header{
  background: transparent;
  background-color: transparent;
  border: none;
}

.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  background: transparent;
  background-color: transparent;
  border: none;
  color: #3F5FE2;
}
</style>

<template>
  <div class="vue-box">
    <el-tabs style="margin: 15px;height: 95%" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="今日数据">
        <ComStaData ref="com-sta—data"></ComStaData>
      </el-tab-pane>
      <el-tab-pane label="昨日数据">
        <YesterdayComStaData ref="yester-com-sta—data"></YesterdayComStaData>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import YesterdayComStaData from "../com-view/yesterday-com-sta-data.vue";
import ComStaData from "./com-sta-data.vue";
export default {
  components: { ComStaData, YesterdayComStaData },
  data() {
    return {
      mchId: null,
      role: null,
      secretKey: null,
    };
  },
  methods: {
    getMchInfo: function () {
      var defaultCfg = {
        type: "get", //是否是请求体请求
      };
      if (this.sa_admin.role == 1) {
        this.sa.ajax(
          "/v1/merchant/getMerchantInfo",
          function (res) {
            this.mchId = res.data.mchId;
            this.secretKey = res.data.secretKey;
          }.bind(this),
          defaultCfg
        );
      }
    },
    handleClick: function (tab) {
      if (tab.index == 0) {
        this.$refs["com-sta—data"].dataAnalysisToDay();
      } else {
        this.$refs["yester-com-sta—data"].dataAnalysisToDay();
      }
    }
  },
  created: function () {
    var defaultCfg2 = {
      type: "get", //是否是请求体请求
    };
    if (this.sa_admin.role == null) {
      this.sa.ajax(
        "/getMerchantRole",
        function (res) {
          this.role = res.data.roles;
          this.sa_admin.role = res.data.roles;
          this.getMchInfo();
        }.bind(this),
        defaultCfg2
      );
    } else {
      this.role = this.sa_admin.role;
      this.getMchInfo();
    }

  },
  mounted: function () {
    //初始化统计数据
    this.$refs["com-sta—data"].dataAnalysisToDay();
  }
};
</script>
