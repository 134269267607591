<!--
 * @Descripttion: 统计数值组件
 * @version: 1.1
 * @Author: sakuya
 * @Date: 2021年6月23日13:11:32
 * @LastEditors: sakuya
 * @LastEditTime: 2022年5月14日19:55:09
-->

<template>
  <div class="sc-statistic">
    <div v-if="image" class="sc-statistic-img">
      <el-image
        :src="require('@/static/icon/'+image)"
        fit="fill"></el-image>
    </div>
    <div :class="[{'sc-statistic-text':image}]">
      <div class="sc-statistic-content">
        <span v-if="prefix" class="sc-statistic-content-prefix">{{ prefix }}</span>
        <span class="sc-statistic-content-value">{{ cmtValue }}</span>
        <span v-if="suffix" class="sc-statistic-content-suffix">{{ suffix }}</span>
      </div>
      <div v-if="description || $slots.default" class="sc-statistic-description">
        <slot>
          {{ description }}
        </slot>
      </div>
      <div class="sc-statistic-title">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: { type: String, default: '' },
    title: { type: String, required: true, default: '' },
    value: { default: '' },
    prefix: { type: String, default: '' },
    suffix: { type: String, default: '' },
    description: { type: String, default: '' },
    tips: { type: String, default: '' },
    groupSeparator: { type: Boolean, default: false },
  },
  data () {
    return {}
  },
  computed: {
    cmtValue () {
      return this.groupSeparator ? this.$TOOL.groupSeparator(this.value) : this.value
    },
  },
}
</script>

<style scoped>

.sc-statistic-title {
  font-size: 12px;
  color: #999;
  display: flex;
  align-items: center;
}

.sc-statistic-tips {
  margin-left: 5px;
}

.sc-statistic-content {
  display: flex;
  font-size: 30px;
  margin-bottom: 10px;
  color: #0D0E49;
}

.sc-statistic-content-value {
  font-weight: bold;
}

.sc-statistic-content-prefix {
  margin-right: 5px;
}

.sc-statistic-content-suffix {
  margin-left: 5px;
  font-size: 12px;
}

.sc-statistic-description {
  margin-top: 10px;
  color: #999;
}

.dark .sc-statistic-content {
  color: #d0d0d0;
}

.el-card:hover .sc-statistic-content{
  color: #fff;
}
.el-card:hover .sc-statistic-title{
  color: #eee;
}

.el-card:hover .sc-statistic svg path {
  fill: red; /* 将填充颜色设置为红色 */
}

.sc-statistic {
  height: 80px;
  display: flex;
  align-items: center;

}

.sc-statistic .sc-statistic-img {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 80px;

}

.sc-statistic .sc-statistic-text {
  float: left;
  margin-left: 30px;
  height: 80px;
  margin-top: 10px ;
}
</style>
